export default {
  data() {
    return {
      formValue: {
        proxyCollectMemberId: null,
        scheduleSettingInfo: {
          firstCollectionDate: "",
          collectionTime: "",
          isRecurring: false,
          registMemberId: "",
        },
        recurringInfo: {
          recurringTypeId: "",
          intervalValue: "",
          weekOfDayTypeList: [],
          recurringEndDate: null,
        },
        routeIds: [], // postDataでは削除
        manifestInfos: [
          // {
          //   "manifestStatusId": 0,
          //   "generateStoreId": 0,
          //   "routeId": 0,
          //   "generateMember": {
          //     "id": 0,
          //     "name": "string"
          //   },
          //   "registMember": {
          //     "id": 0,
          //     "name": "string"
          //   },
          //   "wasteInfo": {
          //     "jwnetItemTypeId": 0,
          //     "wasteNameId": 0,
          //     "detailWasteName": "string",
          //     "wasteQuantity": 0,
          //     "wasteUnitTypeId": 0,
          //     "quantityConfirmBusinessTypeId": 0,
          //     "shapeTypeId": 0,
          //     "shapeQuantity": 0,
          //     "disposalWayTypeId": 0
          //   },
          //   "harmfulInfo": [
          //     {
          //       "harmfulTypeId": 0
          //     }
          //   ],
          //   "remarksInfo": {
          //     "remarksList": [
          //       {
          //         "remarks": "string"
          //       }
          //     ]
          //   },
          //   "contactNoInfo": {
          //     "contactNoList": [
          //       {
          //         "contactNo": "string"
          //       }
          //     ]
          //   },
          //   "labelInfo": {
          //     "labels": [
          //       {
          //         "labelId": 0,
          //         "labelValue": "string"
          //       }
          //     ]
          //   },
          //   "anyGenerateStoreInfo": {
          //     "anyGenerateStoreName": "string",
          //     "anyGenerateStoreAddress": {
          //       "zipCode": "string",
          //       "prefecturesId": 0,
          //       "address": "string",
          //       "buildingName": "string"
          //     }
          //   },
          //   "conversionFactorKg": 0,
          //   "conversionFactorM3": 0,
          //   "conversionFactorLiter": 0,
          //   "conversionFactorUnitNumber": 0
          // }
        ],
      },
    };
  },
  methods: {},
};
