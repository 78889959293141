<template>
<div class="comma_text">
  {{ this.dottedText(item, maxLength)}}
  <div
      v-if="item.length > maxLength"
      class="c-infoList__badge"
  >
    詳
    <div class="c-infoList__popup">
        <span>{{item}}</span>
    </div>
  </div>
</div>
</template>

<script>
import common from "@/mixin/common";

export default {
  props: {
    maxLength: {
      type: Number, 
      default: 17,
    } ,
    item: {
      type: String,
      default: null,
    },
  },
  mixins: [common]
};
</script>

<style>
.comma_text {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
</style>