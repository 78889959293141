<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="マニフェスト代理予約（複数）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- マニフェスト情報 -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">マニフェスト情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>引き渡し日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :value.sync="
                      formValue.scheduleSettingInfo.firstCollectionDate
                    "
                    :placeholder="'0000/00/00'"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.collectionDate.isValid"
                    :errMsg.sync="validate.collectionDate.errMsg"
                  />
                  <InputTime
                    class="sm"
                    :value.sync="formValue.scheduleSettingInfo.collectionTime"
                    :placeholder="'00:00'"
                    :validation="validateJustBlank"
                  />
                </div>
                <div class="c-inputWrap__items__cols l-area">
                  <Selectbox
                    v-if="formValue.scheduleSettingInfo.firstCollectionDate"
                    name="recurrying"
                    :value.sync="selectedRecurrying"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.selectedRecurrying.isValid"
                    :errMsg.sync="validate.selectedRecurrying.errMsg"
                    :change="onChangeRecurryingHandler"
                  >
                    <option value="">繰り返さない</option>
                    <option value="every-day">毎日</option>
                    <option value="every-week">
                      毎週{{ getFirstCollectionDateWeekOfDayLabel() }}曜日
                    </option>
                    <option value="custom">カスタム</option>
                  </Selectbox>
                </div>
                <div
                  class="c-inputWrap__items__cols center sm-mgn"
                  v-if="selectedRecurrying === 'custom'"
                >
                  <div>
                    {{ formValue.recurringInfo.intervalValue }}
                    {{
                      formValue.recurringInfo.recurringTypeId ===
                      recurryingType.DAY
                        ? "日ごと"
                        : formValue.recurringInfo.recurringTypeId ===
                          recurryingType.WEEK
                        ? "週間ごと"
                        : ""
                    }}
                    {{
                      weekOfDayTypeIdList
                        .map((id) => weekOfDay[id - 1].label + "曜日")
                        .join("、")
                    }}
                  </div>
                  <div
                    class="c-btn secondary sm"
                    @click="isRecurryingModalShow = true"
                  >
                    カスタム設定
                  </div>
                </div>
              </div>
            </div>
            <!-- 繰り返すのを設定する場合のみ、繰り返しの終了日を表示する -->
            <div
              v-if="
                selectedRecurrying !== '' &&
                formValue.scheduleSettingInfo.firstCollectionDate !== ''
              "
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>繰り返し終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :validation="validateJustBlank"
                    :value.sync="formValue.recurringInfo.recurringEndDate"
                    :placeholder="'0000/00/00'"
                    :isValid.sync="validate.recurringEndDate.isValid"
                    :errMsg.sync="validate.recurringEndDate.errMsg"
                  />
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>代理登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="emit-in-charge"
                        :value.sync="formValue.proxyCollectMemberId"
                        :validation="validateJustBlank"
                      >
                        <option
                          v-for="opt in collectMemberOpt"
                          :key="'pgc' + opt.id"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWra -->
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 廃棄物情報 -->
        <div class="l-contents l-area">
          <div class="l-box">
            <h3 class="c-lead icon">廃棄物情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>ルート</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="non_side_layout">
                  <div
                    class="c-btn secondary small l-item"
                    @click="showRouteSelectModal()"
                  >
                    ルート選択
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ErrorMessage :errMsgs="errMsgsRouteCount" />
          <template v-if="formValue.manifestInfos.length > 0">
            <div
              class="l-block-lg"
              v-for="(route, route_index) in formValue.manifestInfos"
              :key="`route_${route_index}`"
            >
              <div class="l-flex -between c-text sub-title -strong -lg">
                <p class="c-text horizontal_dot">
                  {{
                    !routeInfo(route.routeId)
                      ? ""
                      : routeInfo(route.routeId).routeName
                  }}
                </p>
                <div>
                  <div
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="removeRouteHandler(route.routeId)"
                    style="min-width: 74px"
                  >
                    削除
                  </div>
                </div>
              </div>
              <div class="l-area-side">
                <div class="c-inputWrap bottom_xs space_between flex_wrap">
                  <div v-if="routeBasicInfo(route.routeId)">
                    <dl class="c-dl">
                      <dt>廃棄物種類：</dt>
                      <dd>
                        {{ routeBasicInfo(route.routeId).jwnetItemTypeName }}
                      </dd>
                    </dl>
                    <dl class="c-dl">
                      <dt>排出事業場：</dt>
                      <dd>
                        {{
                          routeBasicInfo(route.routeId).routeCompanyName
                            .generateStore
                        }}
                      </dd>
                    </dl>
                    <dl class="c-dl">
                      <dt>運搬業者：</dt>
                      <dd>
                        {{
                          routeBasicInfo(route.routeId).routeCompanyName
                            .collectCompany
                        }}
                      </dd>
                    </dl>
                    <dl class="c-dl">
                      <dt>処分場：</dt>
                      <dd>
                        {{
                          routeBasicInfo(route.routeId).routeCompanyName
                            .disposalSite
                        }}
                      </dd>
                    </dl>
                    <dl
                      v-for="alert in routePriorConsultationAlertMsgs.filter(
                        (msg) => msg.routeId === route.routeId
                      )"
                      v-bind:key="alert"
                      class="c-dl"
                    >
                      <span
                        v-if="alert.routePriorconsultationIsLimit"
                        class="c-text icon icon-before danger"
                        >{{ alert.msg }}</span
                      >
                      <span
                        v-if="alert.routePriorconsultationIsAlert"
                        class="c-text icon icon-before warning"
                        >{{ alert.msg }}</span
                      >
                    </dl>
                  </div>
                </div>

                <div class="c-inputWrap flex_wrap">
                  <div class="c-inputMemberOpt">
                    <div class="c-inputWrap__label">
                      <label>登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap__items__cols">
                        <div class="c-radio">
                          <input
                            :id="'register-select-' + route_index"
                            type="radio"
                            :value="true"
                            v-model="route.isInputRegisterMemberForm"
                            v-on:change="
                              changeRegistMemberInputForm(route.routeId)
                            "
                          />
                          <label
                            class="c-radio__label"
                            :for="'register-select-' + route_index"
                          >
                            <span class="c-radio__box"></span>リストから選択
                          </label>
                        </div>
                        <div class="c-radio">
                          <input
                            :id="'register-input-' + route_index"
                            type="radio"
                            :value="false"
                            v-model="route.isInputRegisterMemberForm"
                            v-on:change="
                              changeRegistMemberInputForm(route.routeId)
                            "
                          />
                          <label
                            class="c-radio__label"
                            :for="'register-input-' + route_index"
                          >
                            <span class="c-radio__box"></span>直接入力
                          </label>
                        </div>
                      </div>
                      <Selectbox
                        v-if="route.isInputRegisterMemberForm"
                        class="c-inputWrap__items__cols"
                        name="register-in-charge"
                        :value.sync="route.registMember.id"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="opt in memberOpts[route.routeId]"
                          :key="'mp' + opt.id"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                      <InputText
                        v-if="!route.isInputRegisterMemberForm"
                        class="c-inputWrap__items__cols"
                        style="width: 22rem"
                        placeholder="入力してください"
                        tooltip="12文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="12"
                        inputType="text"
                        input_id="input_registerMember"
                        :value.sync="route.registMember.name"
                        :isValid.sync="
                          validate.routes[route.routeId].registMemberName
                            .isValid
                        "
                        :errMsg.sync="
                          validate.routes[route.routeId].registMemberName.errMsg
                        "
                      />
                    </div>
                  </div>
                  <div class="c-inputMemberOpt">
                    <div class="c-inputWrap__label">
                      <label>引き渡し担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap__items__cols">
                        <div class="c-radio">
                          <input
                            :id="'generate-select-' + route_index"
                            type="radio"
                            :value="true"
                            v-model="route.isInputGenerateMemberForm"
                            v-on:change="
                              changeGenerateMemberInputForm(route.routeId)
                            "
                          />
                          <label
                            class="c-radio__label"
                            :for="'generate-select-' + route_index"
                          >
                            <span class="c-radio__box"></span>リストから選択
                          </label>
                        </div>
                        <div class="c-radio">
                          <input
                            :id="'generate-input-' + route_index"
                            type="radio"
                            :value="false"
                            v-model="route.isInputGenerateMemberForm"
                            v-on:change="
                              changeGenerateMemberInputForm(route.routeId)
                            "
                          />
                          <label
                            class="c-radio__label"
                            :for="'generate-input-' + route_index"
                          >
                            <span class="c-radio__box"></span>直接入力
                          </label>
                        </div>
                      </div>
                      <Selectbox
                        v-if="route.isInputGenerateMemberForm"
                        class="c-inputWrap__items__cols"
                        name="generate-in-charge"
                        :value.sync="route.generateMember.id"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="opt in memberOpts[route.routeId]"
                          :key="'mp' + opt.id"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                      <InputText
                        v-if="!route.isInputGenerateMemberForm"
                        class="c-inputWrap__items__cols"
                        style="width: 22rem"
                        placeholder="入力してください"
                        tooltip="12文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="12"
                        inputType="text"
                        input_id="input_generateMember"
                        :value.sync="route.generateMember.name"
                        :isValid.sync="
                          validate.routes[route.routeId].generateMemberName
                            .isValid
                        "
                        :errMsg.sync="
                          validate.routes[route.routeId].generateMemberName
                            .errMsg
                        "
                      />
                    </div>
                  </div>
                </div>

                <!-- 事業場変更可能フラグがONのルートを選択時   -->
                <template
                  v-if="
                    routeInfo(route.routeId) &&
                    routeInfo(route.routeId).isChangeStore
                  "
                >
                  <div class="c-inputWrap">
                    <div>
                      <div class="c-inputWrap__label">
                        <label>排出事業場名</label>
                      </div>
                      <div>
                        <InputText
                          :class="'xll'"
                          :isMultipleReserve="true"
                          :value.sync="
                            route.anyGenerateStoreInfo.anyGenerateStoreName
                          "
                          inputTyle="text"
                          :validation="validateMaxLen"
                          tooltip="60文字以内で入力してください"
                          :validateArg="60"
                          :isValid.sync="
                            validate.routes[route.routeId].anyGenerateStoreName
                              .isValid
                          "
                          :errMsg.sync="
                            validate.routes[route.routeId].anyGenerateStoreName
                              .errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div class="c-inputWrap c-inputWrap__label">
                    <label>排出事業場住所</label>
                  </div>
                  <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo_wrap">
                    <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo">
                      <div>
                        <div class="c-inputWrap__label">
                          <label>郵便番号</label>
                        </div>
                        <div>
                          <div class="c-inputWrap__items__cols center sm-mgn">
                            <InputText
                              :value.sync="
                                route.anyGenerateStoreInfo
                                  .anyGenerateStoreAddress.zipCode
                              "
                              inputTyle="number"
                              placeholder="1234567(ハイフンなし)"
                              tooltip="7桁の半角数字で入力してください。"
                              :validation="validateNumberEqualLen"
                              :validateArg="7"
                              :isValid.sync="
                                validate.routes[route.routeId].zipCode.isValid
                              "
                              :errMsg.sync="
                                validate.routes[route.routeId].zipCode.errMsg
                              "
                            />
                            <div
                              class="c-btn secondary sm"
                              @click="searchAddressHandler(route.routeId)"
                            >
                              住所自動入力
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="c-inputWrap__label">
                          <label>都道府県</label>
                        </div>
                        <div>
                          <div>
                            <Selectbox
                              class=""
                              name="prefecture"
                              :value.sync="
                                selectedPrefectureTemp.find(
                                  (pref) => pref.routeId === route.routeId
                                ).pref
                              "
                              :validation="validateJustBlank"
                              :isValid.sync="
                                validate.routes[route.routeId].prefecture
                                  .isValid
                              "
                              :errMsg.sync="
                                validate.routes[route.routeId].prefecture.errMsg
                              "
                            >
                              <option
                                v-for="prefecture in prefectures"
                                :key="prefecture.id"
                                :value="prefecture.name"
                              >
                                {{ prefecture.name }}
                              </option>
                            </Selectbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-inputWrap flex_wrap -anyGenerateStoreInfo">
                      <div>
                        <div class="c-inputWrap__label">
                          <label>市区町村</label>
                        </div>
                        <div>
                          <div>
                            <InputText
                              class="lg"
                              :value.sync="
                                route.anyGenerateStoreInfo
                                  .anyGenerateStoreAddress.address
                              "
                              inputTyle="text"
                              placeholder="市区町村"
                              tooltip="100文字以内で入力してください"
                              :validation="validateMaxLen"
                              :validateArg="100"
                              :isValid.sync="
                                validate.routes[route.routeId].address.isValid
                              "
                              :errMsg.sync="
                                validate.routes[route.routeId].address.errMsg
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="c-inputWrap__label">
                          <label>番地建物名号室</label>
                        </div>
                        <div>
                          <div>
                            <InputText
                              class="lg"
                              :value.sync="
                                route.anyGenerateStoreInfo
                                  .anyGenerateStoreAddress.buildingName
                              "
                              inputTyle="text"
                              placeholder="番地建物名号室"
                              tooltip="40文字以内で入力してください"
                              :validation="validateMaxLen"
                              :validateArg="40"
                              :isValid.sync="
                                validate.routes[route.routeId].buildingName
                                  .isValid
                              "
                              :errMsg.sync="
                                validate.routes[route.routeId].buildingName
                                  .errMsg
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="c-inputWrap flex_wrap">
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>廃棄物名称</label></span
                    >
                    <Selectbox
                      name="disposal-kind"
                      :value.sync="route.wasteInfo.wasteNameId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(wasteName, index) in findArrByRouteId(
                          route.routeId,
                          'wasteName'
                        )"
                        :key="'waste-name' + index"
                        :value="wasteName.id"
                      >
                        {{ wasteName.name }}
                      </option>
                    </Selectbox>
                  </div>
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>詳細名称</label></span
                    >
                    <InputText
                      :class="'xlg_md'"
                      :value.sync="route.wasteInfo.detailWasteName"
                      placeholder="詳細名称"
                      tooltip="30文字以内で入力してください"
                      :validation="validateMaxLen"
                      :validateArg="30"
                      :isValid.sync="
                        validate.routes[route.routeId].detailWasteName.isValid
                      "
                      :errMsg.sync="
                        validate.routes[route.routeId].detailWasteName.errMsg
                      "
                    />
                  </div>
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>処分方法</label></span
                    >
                    <Selectbox
                      name="package-name"
                      :value.sync="route.wasteInfo.disposalWayTypeId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(opt, index) in findArrByRouteId(
                          route.routeId,
                          'disposalWayTypes'
                        )"
                        :key="'dwt' + index"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                  </div>
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>廃棄物数量</label></span
                    >
                    <div class="c-inputWrap__items__cols">
                      <InputText
                        class="mid"
                        inputType="number"
                        :value.sync="route.wasteInfo.wasteQuantity"
                        tooltip="廃棄物数量は数字で入力してください。"
                        :validation="validateNumberOrFloat"
                        :isValid.sync="
                          validate.routes[route.routeId].wasteQuantity.isValid
                        "
                        :errMsg.sync="
                          validate.routes[route.routeId].wasteQuantity.errMsg
                        "
                      />
                      <Selectbox
                        class="sm"
                        name="unit"
                        :value.sync="route.wasteInfo.wasteUnitTypeId"
                        :validation="validateJustBlank"
                        :isValid.sync="
                          validate.routes[route.routeId].wasteUnitTypeId.isValid
                        "
                        :errMsg.sync="
                          validate.routes[route.routeId].wasteUnitTypeId.errMsg
                        "
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(opt, index) in wasteUnitOpt"
                          :key="'wu' + index"
                          :value="opt.id"
                        >
                          {{ opt.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                  <div>
                    <span class="c-inputWrap__label"><label>荷姿</label></span>
                    <Selectbox
                      name="package-name"
                      formSize="mid"
                      :value.sync="route.wasteInfo.shapeTypeId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        :key="'shp' + index"
                        v-for="(opt, index) in findArrByRouteId(
                          route.routeId,
                          'shapes'
                        )"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                  </div>
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>荷姿数量</label></span
                    >
                    <InputText
                      :class="'sm'"
                      :value.sync="route.wasteInfo.shapeQuantity"
                      inputType="number"
                      :validation="validateJustBlank"
                      :errMsg.sync="
                        validate.routes[route.routeId].shapeQuantity.errMsg
                      "
                    />
                  </div>
                  <div>
                    <span class="c-inputWrap__label"
                      ><label>数量確定者</label></span
                    >
                    <Selectbox
                      name="number-in-charge"
                      formSize="mid"
                      :value.sync="
                        route.wasteInfo.quantityConfirmBusinessTypeId
                      "
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="(opt, index) in confirmMemberOpt[route.routeId]"
                        :key="'cm' + index"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                  </div>
                </div>

                <div
                  v-if="isDisplayHarmfulSelection(route.routeId)"
                  class="c-inputWrap flex_wrap"
                >
                  <div class="c-inputWrap__label flex full">
                    <label>有害物質情報</label>
                  </div>
                  <div class="c-inputWrap__items__cols wrap full left_sm">
                    <div
                      v-for="(harmfulItem, itemIndex) in route.harmfulInfo"
                      :key="`hmfl-item-${itemIndex}`"
                      class="l-flex start"
                    >
                      <Selectbox
                        class="l-area"
                        name="package-name"
                        :value.sync="harmfulItem.harmfulTypeId"
                        :validation="validateJustBlank"
                      >
                        <option :value="null"></option>
                        <option
                          v-for="(harmfulType, index) in harmfulTypes"
                          :key="'hmfl-' + index"
                          :value="harmfulType.id"
                        >
                          {{ harmfulType.name }}
                        </option>
                      </Selectbox>
                      <div
                        class="c-input__addBtn c-btn secondary delete sm"
                        @click="
                          removeHarmfulTypeHandler(harmfulItem, route.routeId)
                        "
                      >
                        削除
                      </div>
                    </div>
                    <div
                      class="c-btn secondary add sm"
                      v-if="route.harmfulInfo && route.harmfulInfo.length < 6"
                      @click="addHarmfulTypeHandler(route.routeId)"
                    >
                      有害物質名を追加
                    </div>
                  </div>
                </div>

                <div class="c-inputWrap flex_wrap">
                  <div
                    v-for="(contact, contactIndex) in route.contactNoInfo
                      .contactNoList"
                    :key="`ctct-item-${contactIndex}`"
                  >
                    <div class="c-inputWrap__label flex full">
                      <label>連絡番号{{ contactIndex + 1 }}</label>
                    </div>
                    <div class="c-inputWrap__items__cols">
                      <div class="c-input l-area">
                        <InputText
                          class="l-area"
                          tooltip="20バイト以内で入力してください。"
                          :value.sync="contact.contactNo"
                          inputTyle="text"
                          :validation="validateJustBlank"
                          :isValid.sync="
                            validate.routes[route.routeId].contactNo[
                              contactIndex
                            ].isValid
                          "
                          :errMsg.sync="
                            validate.routes[route.routeId].contactNo[
                              contactIndex
                            ].errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="c-inputWrap flex_wrap"
                  style="margin-top: 0; row-gap: 0"
                >
                  <div
                    class="l-flex start"
                    style="margin-top: 0"
                    v-for="(remarks, remarkIndex) in route.remarksInfo
                      .remarksList"
                    :key="'remarks' + remarkIndex"
                  >
                    <div class="c-inputWrap__label flex full">
                      <label>備考{{ remarkIndex + 1 }}</label>
                    </div>
                    <div class="c-inputWrap__items__cols">
                      <div
                        class="c-input l-area xlg_md"
                        style="margin-bottom: 1rem"
                      >
                        <InputText
                          class="l-area xlg_md"
                          style="margin-left: 1rem"
                          tooltip="備考は50バイト以内で入力してください。"
                          :value.sync="remarks.remarks"
                          inputType="text"
                          :validation="validateByteLen"
                          :validateArg="50"
                          :isValid.sync="
                            validate.routes[route.routeId].remarks[remarkIndex]
                              .isValid
                          "
                          :errMsg.sync="
                            validate.routes[route.routeId].remarks[remarkIndex]
                              .errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="c-inputWrap flex_wrap">
                  <div
                    v-for="(label, labelIndex) in route.labelInfo.labelList"
                    :key="'label' + labelIndex"
                  >
                    <span class="c-inputWrap__label">
                      <label>
                        {{ dottedText(label.labelHeader, 20) }}
                      </label>
                    </span>
                    <div class="c-inputWrap__items__cols">
                      <InputText
                        :class="{
                          lg: !label.labelUnit,
                          sm: label.labelUnit,
                        }"
                        tooltip="50文字以内で入力してください。"
                        :value.sync="label.labelDefaultValue"
                        inputType="text"
                        :validation="validateMaxLen"
                        :validateArg="50"
                        :isValid.sync="
                          validate.routes[route.routeId].labelValue[labelIndex]
                            .isValid
                        "
                        :errMsg.sync="
                          validate.routes[route.routeId].labelValue[labelIndex]
                            .errMsg
                        "
                      />
                      <span v-if="label.labelUnit" style="width: 80px">
                        {{ dottedText(label.labelUnit, 10) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- /.l-contents -->
      </div>
      <FloatButtons>
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">
            戻る
          </div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">
            登録
          </div>
        </div>
      </FloatButtons>
    </main>

    <!-- 繰り返しモーダル -->
    <div class="c-modal md" v-show="isRecurryingModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">繰り返し設定</h5>
          <span
            class="c-modal__head__close"
            @click="closeCustomRecurryModalHandler"
          ></span>
        </div>
        <div class="c-modal__body">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>間隔</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <Selectbox
                  class="sm"
                  name="other-route-num"
                  :value.sync="formValue.recurringInfo.intervalValue"
                  :validation="validateNumber"
                >
                  <option
                    v-for="n in getIntervals"
                    :key="'intv' + n"
                    :value="n"
                  >
                    {{ n }}
                  </option>
                </Selectbox>
                <Selectbox
                  class="mid"
                  name="other-route-period"
                  :value.sync="formValue.recurringInfo.recurringTypeId"
                  :validation="validateJustBlank"
                >
                  <option
                    v-for="(opt, index) in recurryingTypesOpt"
                    :key="'rct' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
          </div>
          <div
            class="c-inputWrap"
            v-if="
              formValue.recurringInfo.recurringTypeId === recurryingType.WEEK
            "
          >
            <div class="c-inputWrap__label">
              <label>曜日</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols l-area">
                <div class="c-checkbox" v-for="day in weekOfDay" :key="day.id">
                  <input
                    :id="'day-cb-' + day.id"
                    type="checkbox"
                    :value="day.id"
                    v-model="weekOfDayTypeIdList"
                  />
                  <label class="c-checkbox__label" :for="'day-cb-' + day.id">
                    <span class="c-checkbox__box"></span>
                    {{ day.label }}
                  </label>
                </div>
              </div>
              <p v-if="!weekOfDayTypeIdList.length" class="c-input__errMsg">
                曜日を選択して下さい
              </p>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="closeCustomRecurryModalHandler"
          >
            閉じる
          </div>
          <div class="c-btn primary small" @click="setCustomRecurryinHandler">
            登録
          </div>
        </div>
      </div>
    </div>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを予約します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div
            class="c-btn secondary small"
            @click="isConfirmModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="submit">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!--ルート選択モーダル-->
    <div class="c-modal route_xlg height_xlg" v-if="isRouteSelectModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">ルート選択</h5>
          <span
            class="c-modal__head__close"
            @click="isRouteSelectModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body">
          <!--検索条件開始-->
          <div>
            <div class="c-inputWrap flex_wrap">
              <!--廃棄物区分条件-->
              <div>
                <span class="text-label">廃棄物種類</span>
                <Selectbox
                  class="mid"
                  :value.sync="routeModalSearchParam.jwnetItemTypeId"
                  :validation="validateJustBlank"
                >
                  <option value="">すべて</option>
                  <option
                    v-for="(opt, index) in jwnetItemsOpt"
                    :key="`jwnet-opt-${index}`"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
              <!-- ルート名称 -->
              <div>
                <span class="text-label">ルート名称</span>
                <InputText
                  class="md_l"
                  :value.sync="routeModalSearchParam.routeName"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <!-- 廃棄物名称 -->
              <div>
                <span class="text-label">廃棄物名称</span>
                <InputText
                  class="md_l"
                  :value.sync="routeModalSearchParam.wasteName"
                  inputType="text"
                  placeholder="廃棄物名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                </InputText>
              </div>
              <!--マニフェスト区分条件-->
              <div>
                <span class="text-label">マニフェスト区分</span>
                <div class="l-flex cols">
                  <div
                    class="c-input"
                    v-for="(manifestType, manifestTypeIndex) in manifestTypeOpt"
                    :key="'manifestType-' + manifestTypeIndex"
                  >
                    <div class="c-checkbox">
                      <input
                        :id="'manifestType-' + manifestTypeIndex"
                        type="checkbox"
                        v-model="routeModalSearchParam.manifestTypeIdList"
                        :value="manifestType.id"
                      />
                      <label
                        class="c-checkbox__label"
                        :for="'manifestType-' + manifestTypeIndex"
                      >
                        <span class="c-checkbox__box"></span>
                        {{ manifestType.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- お気に入り -->
              <div>
                <span class="text-label">お気に入り</span>
                <div class="l-flex cols">
                  <div class="c-input">
                    <div class="c-checkbox">
                      <input
                        id="is-favorite"
                        type="checkbox"
                        v-model="routeModalSearchParam.isFavorite"
                      />
                      <label class="c-checkbox__label" for="is-favorite">
                        <span class="c-checkbox__box"></span>
                        有
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap flex_wrap">
              <!-- 処分事業者 -->
              <div>
                <span class="text-label">処分事業者</span>
                <v-autocomplete
                  ref="disposal_autocomplete"
                  @change="
                    (s) => {
                      onChange(s, 'disposal_autocomplete');
                    }
                  "
                  placeholder="処分事業者名称を入力"
                  class="c-input md_l"
                  :items="this.selectDisposalCompanyList"
                  :min-len="0"
                  :wait="300"
                  @update-items="updateDisposalCompanyList"
                  v-model="selectedDisposalCompany"
                  :component-item="selectTemplate"
                  :auto-select-one-item="this.autoSelect"
                  :get-label="getLabel"
                >
                </v-autocomplete>
              </div>
              <!-- 処分場 -->
              <div>
                <span class="text-label">処分場</span>
                <v-autocomplete
                  ref="disposal_site_autocomplete"
                  @change="
                    (s) => {
                      onChange(s, 'disposal_site_autocomplete');
                    }
                  "
                  placeholder="処分場名称を入力"
                  class="c-input md_l"
                  :items="this.selectDisposalSiteList"
                  :min-len="0"
                  :wait="300"
                  @update-items="updateDisposalSiteList"
                  v-bind:initItems="disposalSiteList"
                  v-model="selectedDisposalSite"
                  :component-item="selectItemShowNameAndJwnetIdTemplate"
                  :auto-select-one-item="this.autoSelect"
                  :get-label="getLabelDisposalSite"
                >
                </v-autocomplete>
              </div>
              <!-- 排出事業者 -->
              <div>
                <span class="text-label">排出事業者</span>
                <v-autocomplete
                  ref="disposal_autocomplete"
                  @change="
                    (s) => {
                      onChange(s, 'disposal_autocomplete');
                    }
                  "
                  placeholder="排出事業者名称を入力"
                  class="c-input md_l"
                  :items="this.selectGenerateCompanyList"
                  :min-len="0"
                  :wait="300"
                  @update-items="updateGenerateCompanyList"
                  v-model="selectedGenerateCompany"
                  :component-item="selectTemplate"
                  :auto-select-one-item="this.autoSelect"
                  :get-label="getLabel"
                >
                </v-autocomplete>
              </div>
              <!-- 排出事業場 -->
              <div>
                <span class="text-label">排出事業場</span>
                <v-autocomplete
                  ref="disposal_site_autocomplete"
                  @change="
                    (s) => {
                      onChange(s, 'disposal_site_autocomplete');
                    }
                  "
                  placeholder="排出事業場名称を入力"
                  class="c-input md_l"
                  :items="this.selectGenerateStoreList"
                  :min-len="0"
                  :wait="300"
                  @update-items="updateGenerateStoreList"
                  v-bind:initItems="generateStoreList"
                  v-model="selectedGenerateStore"
                  :auto-select-one-item="this.autoSelect"
                  :get-label="getLabel"
                >
                </v-autocomplete>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <!--廃棄物区分条件-->
                <div>
                  <span class="text-label">廃棄物区分</span>
                  <div class="l-flex cols">
                    <div
                      class="c-input"
                      v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt"
                      :key="'wasteTypeId-' + wtIndex"
                    >
                      <div class="c-checkbox">
                        <input
                          :id="'wasteTypeId-' + wtIndex"
                          type="checkbox"
                          v-model="routeModalSearchParam.wasteTypeIdList"
                          :value="wasteTypeId.id"
                        />
                        <label
                          class="c-checkbox__label"
                          :for="'wasteTypeId-' + wtIndex"
                        >
                          <span class="c-checkbox__box"></span>
                          {{ wasteTypeId.name }}
                        </label>

                        <!--産業廃棄物区分の場合-->
                        <template
                          v-if="
                            wasteTypeId.id === wasteTypeIdOpt[0].id &&
                            routeModalSearchParam.wasteTypeIdList.includes(
                              wasteTypeId.id
                            )
                          "
                        >
                          <div class="l-flex wrap full">
                            <span>(</span>
                            <div
                              class="c-input"
                              v-for="(
                                industrialWasteType, industrialWasteTypeIndex
                              ) in industrialWasteJwnetTypeOpt"
                              :key="
                                'industrialWasteType-' +
                                industrialWasteTypeIndex
                              "
                            >
                              <input
                                :id="
                                  'industrialWasteType-' +
                                  industrialWasteTypeIndex
                                "
                                type="checkbox"
                                v-model="
                                  routeModalSearchParam.industrialWasteJwnetTypeIdList
                                "
                                :value="industrialWasteType.id"
                              />
                              <label
                                class="c-checkbox__label"
                                :for="
                                  'industrialWasteType-' +
                                  industrialWasteTypeIndex
                                "
                              >
                                <span class="c-checkbox__box"></span>
                                {{ industrialWasteType.name }}
                              </label>
                            </div>
                            <span>)</span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="l-container errMsg_float_wrap">
              <ErrorMessage :errMsgs="errMsgsRouteModal" class="errMsg_float" />
              <div class="l-btns l-block" style="padding-left: 3rem">
                <div
                  class="c-btn secondary small"
                  @click="resetRouteModalSearchHandler"
                >
                  条件をリセット
                </div>
                <div
                  class="c-btn primary small"
                  @click="routeModalSearchHandler"
                >
                  検索
                </div>
              </div>
            </div>
          </div>
          <!--検索条件終了-->

          <!--検索結果表示開始-->
          <div class="c-infoList__wrap fit">
            <div class="c-infoList l-box -border">
              <div class="c-infoList__row head sticky">
                <!-- 全選択/解除 -->
                <div class="c-infoList__cell cb left_sticky">
                  <div class="c-checkbox">
                    <input
                      id="check-all-manifests"
                      type="checkbox"
                      v-model="isAllChecked"
                      @change="changeAllCheckHandler"
                    />
                    <label class="c-checkbox__label" for="check-all-manifests">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div class="c-infoList__cell md">ルート名称</div>
                <div class="c-infoList__cell sm">廃棄物区分</div>
                <div class="c-infoList__cell sm">廃棄物種類</div>
                <div class="c-infoList__cell sm">廃棄物名称</div>
                <div class="c-infoList__cell sm-m">排出事業者</div>
                <div class="c-infoList__cell md_m">排出事業場</div>
                <div class="c-infoList__cell md">処分場</div>
              </div>
              <label
                v-for="item of this.narrowedRoutesList"
                :key="'route' + item.routeId"
                class="c-infoList__row list variable_height"
                :class="{
                  checked: selectedRouteIdsTemp.includes(item.routeId),
                }"
                :for="'route' + item.routeId"
              >
                <div class="c-infoList__cell cb left_sticky">
                  <div class="c-checkbox">
                    <input
                      :id="'route' + item.routeId"
                      type="checkbox"
                      :value="item.routeId"
                      v-model="selectedRouteIdsTemp"
                      :disabled="isDisabledCheckbox(item.routeId)"
                    />
                    <label
                      class="c-checkbox__label"
                      :for="'route' + item.routeId"
                    >
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
                <div class="c-infoList__cell md">
                  <div class="c-text vertical_dot">{{ item.routeName }}</div>
                </div>
                <div class="c-infoList__cell sm">
                  <div class="c-text horizontal_dot">
                    {{ item.wasteType.name }}/{{
                      item.manifestType.id === 1 ? "電子" : "紙"
                    }}
                  </div>
                </div>
                <div class="c-infoList__cell sm">
                  <div class="c-text horizontal_dot">
                    {{ item.jwnetItemType.name }}
                  </div>
                </div>
                <div class="c-infoList__cell sm">
                  <div class="c-text horizontal_dot">
                    {{ item.jwnetItemType.wasteName }}
                  </div>
                </div>
                <div class="c-infoList__cell sm-m">
                  <div class="c-text horizontal_dot">
                    {{ item.generateCompany.name }}
                  </div>
                </div>
                <div class="c-infoList__cell md_m">
                  <DottedText :item="item.generateStore.name" :maxLength="14" />
                </div>
                <div class="c-infoList__cell md">
                  <div class="c-text vertical_dot">
                    {{ item.disposalSite.name + " JWNETID:" + item.jwnetId }}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <!--検索結果表示終了-->
        </div>

        <div class="c-modal__foot">
          <div
            class="c-btn primary small"
            :class="{ disabled: isDisabledRouteModal }"
            @click="clickSetRoute(selectedRouteIdsTemp)"
          >
            OK
          </div>
        </div>
      </div>
    </div>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      @selectAddress="selectAddressHandler"
    />

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import InputTime from "@/components/parts/input/InputTime";
import AddressSelectModal from "./components/AddressSelectModal.vue";
import validation from "@/mixin/validation";
import validate from "./mixins/validate";
import reserveFormInfo from "./mixins/reserveFormInfo";
import callApi from "./mixins/callApi";
import recurrying from "./mixins/recurrying";
import route from "./mixins/route";
import harmfulType from "./mixins/harmfulType";
import addressSearch from "./mixins/addressSearch";
import common from "@/mixin/common";
import {
  API_URL,
  WEEK_OF_DAY,
  DOMAIN,
  CUSTOM_MAX_INTERVAL,
  COOKIE_INFO,
  MANIFEST_MULTIPLE_SET_ROUTE_MAX,
} from "@/const/index";
import moment from "moment";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";
import DottedText from "@/components/parts/DottedText.vue";
import FloatButtons from "@/components/layout/FloatButtons.vue";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons.vue";

export default {
  name: "manifest-reserve",
  components: {
    ModalFooterButtons,
    FloatButtons,
    DottedText,
    InputText,
    Selectbox,
    InputDate,
    InputTime,
    AddressSelectModal,
  },
  mixins: [
    addressSearch,
    validation,
    validate,
    reserveFormInfo,
    callApi,
    recurrying,
    route,
    harmfulType,
    common,
  ],
  data() {
    return {
      selectedDisposalCompany: "", //選択した処分事業者
      selectedDisposalSite: "", //選択した処分場
      selectedGenerateCompany: "", //選択した排出事業者
      selectedGenerateStore: "", //選択した排出事業場
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate:
        SelectItemShowNameAndJwnetIdTemplate,
      isAllChecked: false,
      selectedRouteIdsTemp: [], // ルート選択モダールにチェックしてたルートIDリスト（OKボタンを押さない）
      preSelectedRouteIds: [], //前回選択したルートIDリスト
      selectedRouteIds: [], // OKボタンを押下した後、選択したルートIDリスト
      errMsgsRouteModal: [],
      isRecurryingModalShow: false,
      isConfirmModalShow: false,
      isRouteSelectModalShow: false,
      selectedRecurrying: "",
      recurryingType: DOMAIN.RECURRING_TYPE,
      weekOfDayTypeIdList: [],
      weekOfDay: WEEK_OF_DAY,
      errMsgs: [],
      errMsgsRouteCount: [],
      selectedJwnetItem: {},
      selectedPrefectureTemp: [],
      wasteTypeIdOpt: [
        { id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE, name: "産業廃棄物" },
        { id: DOMAIN.WASTE_TYPE.VALUABLES, name: "有価物" },
        { id: DOMAIN.WASTE_TYPE.COMMON_WASTE, name: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        {
          id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE
            .IS_NOT_SPECIAL_INDUSTRIAL_WASTE,
          name: "普通産業廃棄物",
        },
        {
          id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE,
          name: "特別管理産業廃棄物",
        },
      ],
      manifestTypeOpt: [
        { id: DOMAIN.MANIFEST_TYPE.DIGITAL, name: "電子" },
        { id: DOMAIN.MANIFEST_TYPE.ANALOG, name: "紙マニフェスト" },
      ],
      // ルート選択肢モーダル検索パラメータ
      routeModalSearchParam: {
        jwnetItemTypeId: "", // 廃棄物種類ID
        routeName: "", // ルート名称
        wasteName: "", // 廃棄物名称
        manifestTypeIdList: [], // マニフェスト区分
        isFavorite: false, // お気に入りフラグ
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, // 処分場ID
        generateCompanyId: null, // 排出事業者Id
        generateStoreId: null, // 排出事業場Id
        wasteTypeIdList: [], //廃棄物区分
        industrialWasteJwnetTypeIdList: [],
      },
      processing: false,
    };
  },
  methods: {
    // 「ルート選択」ボタンを押すと、選択モーダルを表示する
    showRouteSelectModal() {
      // 設定した条件があればそのままにする、ない場合、default値で設定
      this.setRouteSelectModalSearchParam();
      this.isRouteSelectModalShow = true;
      this.routeModalSearchHandler();
    },

    // 設定した条件があればそのままにひょうじする
    setRouteSelectModalSearchParam() {
      const currentSearchParam = this.routeModalSearchParam;
      this.routeModalSearchParam = {
        jwnetItemTypeId:
          currentSearchParam.jwnetItemTypeId !== ""
            ? currentSearchParam.jwnetItemTypeId
            : "",
        routeName:
          currentSearchParam.routeName !== ""
            ? currentSearchParam.routeName
            : "",
        wasteName:
          currentSearchParam.wasteName !== ""
            ? currentSearchParam.wasteName
            : "",
        collectCompanyId: currentSearchParam.collectCompanyId,
        disposalCompanyId: currentSearchParam.disposalCompanyId,
        disposalSiteId: currentSearchParam.disposalSiteId,
        wasteTypeIdList:
          currentSearchParam.wasteTypeIdList.length > 0
            ? currentSearchParam.wasteTypeIdList
            : [],
        manifestTypeIdList:
          currentSearchParam.manifestTypeIdList.length > 0
            ? currentSearchParam.manifestTypeIdList
            : [],
        industrialWasteJwnetTypeIdList:
          currentSearchParam.industrialWasteJwnetTypeIdList.length > 0
            ? currentSearchParam.industrialWasteJwnetTypeIdList
            : [],
        isFavorite:
          currentSearchParam.isFavorite !== false
            ? currentSearchParam.isFavorite
            : false,
      };
    },

    // ルート選択肢モーダルに「検索条件をリセット」ボタンを押すと
    initRouteModalSearchParam() {
      this.selectedDisposalCompany = "";
      this.selectedDisposalSite = "";
      this.selectedGenerateCompany = "";
      this.selectedGenerateStore = "";
      this.disposalSiteList = this.disposalSiteOptions;
      this.generateStoreList = this.generateStoreOptions;
      this.routeModalSearchParam = {
        jwnetItemTypeId: "",
        routeName: "",
        wasteName: "",
        manifestTypeIdList: [], // マニフェスト区分
        isFavorite: false,
        disposalCompanyId: null, // 処分事業者Id
        disposalSiteId: null, // 処分場ID
        generateCompanyId: null, // 排出事業者Id
        generateStoreId: null, // 排出事業場Id
        wasteTypeIdList: [], //廃棄物区分
        industrialWasteJwnetTypeIdList: [],
      };
    },

    // ルート選択肢モーダルの検索情報をクリアする
    resetRouteModalSearchHandler() {
      this.initRouteModalSearchParam();
    },

    // ルート選択肢モーダルに「検索」ボタンを押す処理
    routeModalSearchHandler() {
      this.getRouteListSearchApi().then(() => {
        this.updateCheckedAllRoute();
      });
    },

    // ルート確定
    clickSetRoute(routeIds) {
      // NOTE: 1ルート以上選択必須とする
      if (routeIds.length === 0) {
        this.errMsgsRouteModal = ["ルートを1つ以上選択してください"];
        return;
      }

      this.errMsgsRouteModal = [];

      this.formValue.routeIds = routeIds;
      this.onChangeRouteHandler(routeIds);

      this.isRouteSelectModalShow = false;
    },

    getFirstCollectionDateWeekOfDayLabel() {
      const target = new Date(
        this.formValue.scheduleSettingInfo.firstCollectionDate
      );
      return WEEK_OF_DAY[target.getDay()].label;
    },

    // 繰り返しのchange処理
    onChangeRecurryingHandler() {
      if (this.selectedRecurrying === "custom") {
        this.isRecurryingModalShow = true;
      }
    },

    // 繰り返しモーダル閉じる（モーダル内設定初期化）
    closeCustomRecurryModalHandler() {
      this.isRecurryingModalShow = false;
    },

    // 繰り替えしモーダル内登録押下
    setCustomRecurryinHandler() {
      switch (this.formValue.recurringInfo.recurringTypeId) {
        case DOMAIN.RECURRING_TYPE.DAY:
          this.weekOfDayTypeIdList = [];
          this.isRecurryingModalShow = false;
          break;
        case DOMAIN.RECURRING_TYPE.WEEK:
          if (this.weekOfDayTypeIdList.length) {
            this.isRecurryingModalShow = false;
          }
      }
    },

    // 画面内下部の登録押下
    checkRequiredHandler() {
      this.errMsgs = [];
      this.runValidate().then((isValidForm) => {
        if (!isValidForm) {
          this.processing = false;
          this.errMsgs.push("入力に誤りがあります。");

          // ルート件数
          const routeCountErrMsg = this.validate.routesCount.errMsg;
          if (!routeCountErrMsg) this.errMsgsRouteCount = [];
          else this.errMsgsRouteCount = [routeCountErrMsg];

          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.isConfirmModalShow = true;
      });
    },

    // 戻るボタン押下
    backHandler() {
      this.$router.go(-1);
    },

    updateDisposalCompanyList(text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectDisposalCompanyList = this.disposalCompanyList;
        return;
      }
      this.selectDisposalCompanyList = this.disposalCompanyList.filter(
        (item) => {
          return new RegExp(text.toLowerCase()).test(item.name.toLowerCase());
        }
      );
    },
    updateDisposalSiteList(text) {
      if (!text || text.length === 0) {
        //全ての処分場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList;
        return;
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return new RegExp(text.toLowerCase()).test(item.name.toLowerCase());
      });
    },
    updateGenerateCompanyList(text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGenerateCompanyList = this.generateCompanyList;
        return;
      }
      this.selectGenerateCompanyList = this.generateCompanyList.filter(
        (item) => {
          return new RegExp(text.toLowerCase()).test(item.name.toLowerCase());
        }
      );
    },
    updateGenerateStoreList(text) {
      if (!text || text.length === 0) {
        //全ての事業場の選択肢を表示する
        this.selectGenerateStoreList = this.generateStoreList;
        return;
      }
      this.selectGenerateStoreList = this.generateStoreList.filter((item) => {
        return new RegExp(text.toLowerCase()).test(item.name.toLowerCase());
      });
    },
    getLabel(item) {
      if (item) {
        return item.name;
      }
      return "";
    },
    getLabelDisposalSite(item) {
      if (item) {
        return item.name + "  JWNETID:" + item.jwnetId;
      }
      return "";
    },
    onChange(queryString, ref) {
      this.$nextTick(() => {
        this.$refs[ref].searchText = queryString;
      });
    },

    /**
     * ルート全件チェックボックス
     *
     * NOTE:
     * 自動で全選択解除になったときに
     * 意図せず全件チェックが外れてしまうので、computed, watchしない
     */
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        const checkedRouteIds = this.selectedRouteIdsTemp.concat(); // コピー

        let defaultCheckedCount = checkedRouteIds.length;

        // NOTE: 無駄なループ回避のため、一旦20件切り出しておく
        const sumRouteIds = this.narrowedRoutesList
          .slice(0, 20)
          .map((list) => {
            // NOTE: 選択済みも含め10件に制限
            if (defaultCheckedCount >= MANIFEST_MULTIPLE_SET_ROUTE_MAX)
              return null;

            const existChecked = checkedRouteIds.includes(list.routeId);

            // 選択済みならスキップ
            if (existChecked) return null;

            defaultCheckedCount += 1;
            return list.routeId;
          })
          .filter((routeId) => routeId !== null);

        this.selectedRouteIdsTemp = [...checkedRouteIds, ...sumRouteIds];
      } else {
        this.selectedRouteIdsTemp = [];
      }
    },
    // 全てルートチェックボックスの状態を判断する
    updateCheckedAllRoute() {
      // 10件 or 仮のルート一覧結果が全てチェックしたら、全選択状態にする
      const areAllElementsChecked =
        this.narrowedRoutesList.length > 0 &&
        this.narrowedRoutesList.every((element) =>
          this.selectedRouteIdsTemp.includes(element.routeId)
        );
      if (
        this.selectedRouteIdsTemp.length >= MANIFEST_MULTIPLE_SET_ROUTE_MAX ||
        areAllElementsChecked
      ) {
        if (!this.isAllChecked) this.isAllChecked = true;
      } else {
        if (this.isAllChecked) this.isAllChecked = false;
      }
    },

    // ルート削除
    removeRouteHandler(routeId) {
      const filterRoute = (targetRouteId) => targetRouteId !== routeId;

      // チェックマークから外す
      this.isAllChecked = false;

      // 選択中のチェックボックスから外す
      this.selectedRouteIdsTemp = this.selectedRouteIdsTemp.filter(
        (selectedRouteId) => filterRoute(selectedRouteId)
      );
      this.preSelectedRouteIds = this.selectedRouteIdsTemp;

      // 選択中のルート情報から外す
      this.selectedRouteIds = this.selectedRouteIds.filter((selectedRoute) =>
        filterRoute(selectedRoute.routeId)
      );

      // APIから取得したルート情報から外す
      this.routeDetailInfos = this.routeDetailInfos.filter((route) =>
        filterRoute(route.routeId)
      );

      // フォームから外す
      this.formValue.manifestInfos = this.formValue.manifestInfos.filter(
        (route) => filterRoute(route.routeId)
      );
      this.formValue.routeIds = this.formValue.routeIds.filter((_routeId) =>
        filterRoute(_routeId)
      );

      // バリデーションから外す
      delete this.validate.manifestInfos[routeId];
    },

    // 登録担当者のラジオボタンを変更すると、登録担当者をリセット
    changeRegistMemberInputForm(routeId) {
      this.formValue.manifestInfos[routeId].registMember.id = null;
      this.formValue.manifestInfos[routeId].registMember.name = null;
      this.validate.routes[routeId].registMemberName = {
        isValid: true,
        errMsg: "",
      };
    },
    // 引き渡し担当者のラジオボタンを変更すると、引き渡し担当者をリセット
    changeGenerateMemberInputForm(routeId) {
      this.formValue.manifestInfos[routeId].generateMember.id = null;
      this.formValue.manifestInfos[routeId].generateMember.name = null;
      this.validate.routes[routeId].generateMemberName = {
        isValid: true,
        errMsg: "",
      };
    },
    // ルートIDで各情報を探す
    findArrByRouteId(routeId, type) {
      let target = [];
      let arrName = "";
      switch (type) {
        case "shapes":
          target = this.shapeOpt;
          arrName = "shapes";
          break;
        case "disposalWayTypes":
          target = this.disposalWayTypesOpt;
          arrName = "disposalWayTypes";
          break;
        case "wasteName":
          target = this.wasteNameOpt;
          arrName = "wasteName";
          break;
        default:
          return [];
      }
      const data = target.find((r) => r.routeId === routeId);
      if (!data) return [];
      return data[arrName];
    },
    setSelectedPrefectureTemp(routeIds) {
      if (routeIds.length < 1) return;
      const newSelectedPrefectureTemp = routeIds.map((routeId) => {
        return {
          routeId,
          pref: "",
        };
      });

      this.selectedPrefectureTemp = [
        ...this.selectedPrefectureTemp,
        ...newSelectedPrefectureTemp,
      ];
    },

    // モーダル内の登録押下
    submit() {
      this.errMsg = [];
      this.processing = true; // 2重submit防止
      const postData = JSON.parse(JSON.stringify(this.formValue));

      // フォーム整形
      delete postData.routeIds;

      // 繰り返し設定
      postData.scheduleSettingInfo.registMemberId =
        this.$store.getters["userInfo/get"].id;
      switch (this.selectedRecurrying) {
        case "": // 繰り返さない
          postData.scheduleSettingInfo.isRecurring = false;
          delete postData.recurringInfo;
          break;
        case "every-day": // 毎日
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId = DOMAIN.RECURRING_TYPE.DAY;
          postData.recurringInfo.intervalValue = 1;
          delete postData.recurringInfo.weekOfDayTypeList;
          break;
        case "every-week": // 毎週x曜日
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId = DOMAIN.RECURRING_TYPE.WEEK;
          postData.recurringInfo.intervalValue = 1;
          postData.recurringInfo.weekOfDayTypeList = [
            {
              weekOfDayTypeId:
                new Date(
                  this.formValue.scheduleSettingInfo.firstCollectionDate
                ).getDay() + 1,
            },
          ];
          break;
        case "custom": // カスタム
          postData.scheduleSettingInfo.isRecurring = true;
          postData.recurringInfo.recurringTypeId =
            this.formValue.recurringInfo.recurringTypeId;
          postData.recurringInfo.intervalValue =
            this.formValue.recurringInfo.intervalValue;
          postData.recurringInfo.weekOfDayTypeList =
            this.weekOfDayTypeIdList.map((id) => {
              return { weekOfDayTypeId: id };
            });
          break;
      }

      if (postData.scheduleSettingInfo.collectionTime === "") {
        postData.scheduleSettingInfo.collectionTime = null;
      }

      if (!this.formValue.scheduleSettingInfo.firstCollectionDate) {
        delete postData.scheduleSettingInfo;
      }

      postData.manifestInfos = postData.manifestInfos.map((route) => {
        // ルート情報取得の代理登録自動承認可能フラグがONの場合マニフェストステータスを 予約登録中(10)、代理登録自動承認可能フラグがOFFの場合、 マニフェストステータスを 予約承認待ち(12)
        const routeDetail = this.routeDetailInfos.find(
          (detail) => detail.routeId === route.routeId
        );
        if (routeDetail) {
          route.manifestStatusId = routeDetail.isAutoApproval
            ? DOMAIN.MANIFEST_STATUS.UNDER_RESERVE
            : DOMAIN.MANIFEST_STATUS.RESERVE_PENDING;
        }

        if (
          route.anyGenerateStoreInfo.anyGenerateStoreName.length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length ===
            0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId
            .length === 0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length ===
            0 &&
          route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName
            .length === 0
        ) {
          delete route.anyGenerateStoreInfo;
        } else {
          if (
            route.anyGenerateStoreInfo.anyGenerateStoreName &&
            route.anyGenerateStoreInfo.anyGenerateStoreName.length === 0
          ) {
            delete route.anyGenerateStoreInfo.anyGenerateStoreName;
          }

          if (
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode
              .length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId
              .length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.address
              .length === 0 &&
            route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName
              .length === 0
          ) {
            delete route.anyGenerateStoreInfo.anyGenerateStoreAddress;
          }
        }

        let anyGenerateStoreInfo = null;
        if (route.anyGenerateStoreInfo) {
          const anyGenerateStoreName = !route.anyGenerateStoreInfo
            .anyGenerateStoreName
            ? null
            : this.toFullWidthString(
                route.anyGenerateStoreInfo.anyGenerateStoreName
              );

          let anyGenerateStoreAddress = null;
          if (route.anyGenerateStoreInfo.anyGenerateStoreAddress) {
            const address =
              route.anyGenerateStoreInfo.anyGenerateStoreAddress.address;
            const noAddress = !address || address.length === 0;
            const addressObj = noAddress
              ? {}
              : { address: this.toFullWidthStringForAddressInfo(address) };

            const buildingName =
              route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName;
            const noBuildingName = !buildingName || buildingName.length === 0;
            const buildingNameObj = noBuildingName
              ? {}
              : {
                  buildingName:
                    this.toFullWidthStringForAddressInfo(buildingName),
                };

            const selectedPrefectureTemp = this.selectedPrefectureTemp;
            const prefTmpOfRoute =
              !selectedPrefectureTemp || selectedPrefectureTemp.length < 1
                ? null
                : selectedPrefectureTemp.find((prefTmp) => {
                    return prefTmp.routeId === route.routeId;
                  });
            let prefecturesId = {};
            if (prefTmpOfRoute) {
              const prefecture = this.prefectures.find((prefecture) => {
                if (prefecture.name !== prefTmpOfRoute.pref) return false;
                return true;
              });
              if (prefecture) {
                prefecturesId = { prefecturesId: prefecture.id };
              }
            }

            anyGenerateStoreAddress = {
              zipCode:
                route.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode,
              ...prefecturesId,
              ...addressObj,
              ...buildingNameObj,
            };
          }

          anyGenerateStoreInfo = {
            anyGenerateStoreName,
            anyGenerateStoreAddress,
          };
        }

        //登録担当者名の再設定
        if (!route.registMember.name && !route.registMember.id) {
          delete route.registMember;
        } else {
          if (route.isInputRegisterMemberForm) {
            // 選択肢の場合、登録担当者名称をIDで再設定
            route.registMember.name = this.memberOpts[route.routeId].filter(
              (m) => m.id == route.registMember.id
            )[0].name;
          } else {
            // 直接入力の場合、nullに変換を行う
            route.registMember.name = this.toFullWidthString(
              this.removeWhitespaceIfOnlyWhitespace(route.registMember.name)
            );
            // 名称がない場合、登録担当者を削除
            if (!route.registMember.name) {
              delete route.registMember;
            }
          }
        }

        //引き渡し担当者名の再設定
        if (!route.generateMember.name && !route.generateMember.id) {
          delete route.generateMember;
        } else {
          if (route.isInputGenerateMemberForm) {
            // 選択肢の場合、引き渡し担当者名称をIDで再設定
            route.generateMember.name = this.memberOpts[route.routeId].filter(
              (m) => m.id == route.generateMember.id
            )[0].name;
          } else {
            // 直接入力の場合、nullに変換を行う
            route.generateMember.name = this.toFullWidthString(
              this.removeWhitespaceIfOnlyWhitespace(route.generateMember.name)
            );
            // 名称がない場合、引き渡し担当者を削除
            if (!route.generateMember.name) {
              delete route.generateMember;
            }
          }
        }

        // 数量確定者は設定できないものであれば、リセットする
        if (
          route.wasteInfo.quantityConfirmBusinessTypeId &&
          !this.confirmMemberOpt[route.routeId].some(
            (confirmMemberOpt) =>
              confirmMemberOpt.id ===
              route.wasteInfo.quantityConfirmBusinessTypeId
          )
        ) {
          route.wasteInfo.quantityConfirmBusinessTypeId = null;
        }

        return {
          routeId: route.routeId,
          generateStoreId: route.generateStoreId,
          manifestStatusId: route.manifestStatusId,
          registMember: route.registMember,
          generateMember: route.generateMember,
          wasteInfo: {
            ...route.wasteInfo,
            // 詳細名称を全角に変換
            detailWasteName: !route.wasteInfo.detailWasteName
              ? null
              : this.toFullWidthString(route.wasteInfo.detailWasteName),
          },
          // harmfulInfoのharmfulTypeIdがnullなら削除
          harmfulInfo: route.harmfulInfo.filter((hi) => hi.harmfulTypeId),
          // 備考
          remarksInfo: {
            remarksList: route.remarksInfo.remarksList.map((remarks) => {
              return { remarks: remarks.remarks };
            }),
          },
          // 連絡番号
          contactNoInfo: {
            contactNoList: route.contactNoInfo.contactNoList.map(
              (contactNo) => {
                return { contactNo: contactNo.contactNo };
              }
            ),
          },
          labelInfo: {
            labels: route.labelInfo.labelList.map((label) => {
              return {
                labelId: label.labelId,
                labelValue: label.labelDefaultValue,
              };
            }),
          },
          anyGenerateStoreInfo,
          conversionFactorKg: route.conversionFactorKg,
          conversionFactorM3: route.conversionFactorM3,
          conversionFactorLiter: route.conversionFactorLiter,
          conversionFactorUnitNumber: route.conversionFactorUnitNumber,
        };
      });

      // ルート複数登録用、マニフェスト予約API
      // manifest/proxy/multipleRoute/create
      axios
        .post(API_URL.MANIFEST.MULTIPLE_PROXY_CREATE, postData)
        .then(() => {
          // マニフェスト一覧へ
          // this.processing = false; // 2重submit解除
          this.$cookies.set(
            COOKIE_INFO.MESSAGE,
            "マニフェストを予約しました。"
          );
          this.$router.push("/manifests/all");
        })
        .catch((err) => {
          this.processing = false; // 2重submit解除
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },

  computed: {
    // 間隔出力1~10、以降30まで10毎
    getIntervals() {
      const count = CUSTOM_MAX_INTERVAL;
      let arry = [];
      for (let i = 1; i <= count; i++) {
        if (i < 10 || i % 10 === 0) {
          arry.push(i);
        }
      }
      return arry;
    },
    // 10件チェックされているときは、無効化する
    isDisabledCheckbox: function () {
      return function (routeId) {
        if (this.selectedRouteIdsTemp.length < 10) return false;
        const checked = this.selectedRouteIdsTemp.includes(routeId);
        if (!checked) return true;
        return false;
      };
    },
    // 1件でも選択しないときは、OKボタンの無効化
    isDisabledRouteModal: function () {
      if (this.selectedRouteIdsTemp.length === 0) return true;
      return false;
    },
    routeInfo: function () {
      return function (routeId) {
        const route = this.routeDetailInfos.find((r) => r.routeId === routeId);
        if (!route) return null;
        return route;
      };
    },
    routeBasicInfo: function () {
      return function (routeId) {
        const route = this.routeDetailInfos.find((r) => r.routeId === routeId);
        if (!route) return null;
        return {
          routeId,
          routeCompanyName: {
            generateStore: route.generateStoreInfo.name,
            collectCompany: route.routeCollectCompanyInfo
              .filter((cc) => cc.collectCompanyInfo.isActive === true)
              .map((cc) => cc.collectCompanyInfo.name)
              .join(","),
            disposalSite: route.routeDisposalCompanyInfo.destinationInfo.name,
          },
          jwnetItemTypeName: route.jwnetItemTypeInfo.name,
        };
      };
    },
    // 有害物質選択肢表示かチェック
    isDisplayHarmfulSelection: function () {
      return function (routeId) {
        const routes = this.formValue.manifestInfos;
        if (!routes || routes.length < 1) return false;

        const route = routes.find((r) => r.routeId === routeId);
        const selectedJwnetItemId = route.wasteInfo.jwnetItemTypeId;
        const filteredJwnetItems = this.jwnetItemsOpt.filter(
          (jwnetItem) => jwnetItem.id === selectedJwnetItemId
        );
        if (filteredJwnetItems.length > 0) {
          return !!filteredJwnetItems[0].isHarmful;
        }

        return false;
      };
    },
  },

  watch: {
    selectedDisposalCompany: function (newVal) {
      this.selectedDisposalSite = "";
      this.routeModalSearchParam.disposalCompanyId = newVal ? newVal.id : "";
      if (this.routeModalSearchParam.disposalCompanyId) {
        this.disposalSiteList = this.disposalSiteOptions.filter(
          (ds) => ds.disposalCompanyId == newVal.id
        );
        this.selectDisposalSiteList = this.disposalSiteList;
      } else {
        this.disposalSiteList = this.disposalSiteOptions;
        this.selectDisposalSiteList = this.disposalSiteList;
      }
    },
    selectedDisposalSite: function (newVal) {
      this.routeModalSearchParam.disposalSiteId = newVal ? newVal.id : "";
    },
    selectedGenerateCompany: function (newVal) {
      this.selectedGenerateStore = "";
      this.routeModalSearchParam.generateCompanyId = newVal ? newVal.id : "";
      if (this.routeModalSearchParam.generateCompanyId) {
        this.generateStoreList = this.generateStoreOptions.filter(
          (ds) => ds.generateCompanyId == newVal.id
        );
        this.selectGenerateStoreList = this.generateStoreList;
      } else {
        this.generateStoreList = this.generateStoreOptions;
        this.selectGenerateStoreList = this.generateStoreList;
      }
    },
    selectedGenerateStore: function (newVal) {
      this.routeModalSearchParam.generateStoreId = newVal ? newVal.id : "";
    },
    isDisabledRouteModal(newVal) {
      if (newVal) this.errMsgsRouteModal = ["ルートを1つ以上選択してください"];
      else this.errMsgsRouteModal = [];
    },
    selectedRouteIdsTemp(newVal) {
      // 10件 or 仮のルート一覧結果が全てチェックしたら、全選択状態にする
      const areAllElementsChecked =
        this.narrowedRoutesList.length > 0 &&
        this.narrowedRoutesList.every((element) =>
          newVal.includes(element.routeId)
        );

      if (
        newVal.length >= MANIFEST_MULTIPLE_SET_ROUTE_MAX ||
        areAllElementsChecked
      ) {
        if (!this.isAllChecked) this.isAllChecked = true;
      } else {
        if (this.isAllChecked) this.isAllChecked = false;
      }
    },
    isRouteSelectModalShow(newVal) {
      // モーダルを開いたときに、前回の選択を復元する
      if (newVal) this.selectedRouteIdsTemp = this.preSelectedRouteIds;
    },
    selectedAddress() {
      const routeId = this.routeIdInsertAddress;
      const route = this.formValue.manifestInfos.find(
        (route) => route.routeId === routeId
      );

      if (!route) return;

      const existTmpPref = this.selectedPrefectureTemp.find(
        (pref) => pref.routeId === routeId
      );
      if (!existTmpPref) {
        this.selectedPrefectureTemp.push({
          routeId,
          pref: this.selectedAddress.address1,
        });
      } else {
        this.selectedPrefectureTemp = this.selectedPrefectureTemp.map(
          (pref) => {
            if (pref.routeId !== routeId) return pref;
            return {
              ...pref,
              pref: this.selectedAddress.address1,
            };
          }
        );
      }

      route.anyGenerateStoreInfo.anyGenerateStoreAddress.address =
        this.selectedAddress.address2;
      route.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName =
        this.selectedAddress.address3;
    },
  },

  mounted() {
    if (this.$route.query.date) {
      this.formValue.scheduleSettingInfo.firstCollectionDate = moment(
        this.$route.query.date
      ).format("YYYY-MM-DD");
    }

    // 初期表示に必要なAPIを呼ぶ
    this.getPrefecturesApi();
    this.getRecurryingApi();
    this.getWasteUnitApi();
    this.getHarmfulApi();
    this.getCollectMemberApi();
    this.getJwnetItemsApi();
    this.getDisposalCompany();
    this.getDisposalSiteApi();
    this.getProxyGenerateCompanyApi();
    this.getProxyGenerateStoreApi();

    // 代理登録担当者の初期値セット
    this.formValue.proxyCollectMemberId =
      this.$store.getters["userInfo/get"].id;
  },
};
</script>

<style scoped lang="scss">
.errMsg_float_wrap {
  position: relative;
  margin-left: -3rem;

  .errMsg_float {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
}

.c-inputMemberOpt {
  display: flex;
  flex: 1;

  .c-inputWrap__items {
    flex-basis: 65%;
    padding-left: 1rem;
  }
}
</style>
