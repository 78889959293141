import axios from "axios";
import { EXTERNAL_API_URL } from "@/const/index";
import axiosJsonpAdapter from "axios-jsonp";

export default {
  data() {
    return {
      isAddressSelectModalShow: false,
      addressResults: [], // 住所検索モーダル内で使用
      selectedAddress: {}, // 【企業情報】住所検索結果（モーダル展開時はモーダル内選択結果）
      selectedInvoiceAddress: {}, // 【請求先】住所検索結果（モーダル展開時はモーダル内選択結果）
      routeIdInsertAddress: "", // 住所を自動入力したいルートのID
    };
  },

  methods: {
    /**
     * 住所検索押下
     */
    searchAddressHandler(routeId) {
      const anyGenerateStoreInfo = this.formValue.manifestInfos.find(
        (route) => route.routeId === routeId
      );
      const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${anyGenerateStoreInfo.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode}`;
      axios
        .get(url, { adapter: axiosJsonpAdapter })
        .then((res) => {
          const results = res.data.results;

          if (results === null) {
            alert("該当住所が見つかりません、郵便番号をご確認ください。");
          } else if (results.length === 1) {
            // 単一住所のみヒット
            this.selectedAddress = results[0];
            this.routeIdInsertAddress = routeId;
          } else {
            // 復数住所のヒット
            this.addressResults = results;
            this.isAddressSelectModalShow = true;
            this.routeIdInsertAddress = routeId;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectAddressHandler(address) {
      this.selectedAddress = address;
      this.addressResults = [];
    },
  },
};
